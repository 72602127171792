<template>
    <div class="role_main">
        <!-- 标题 -->
        <div class="title_page">
            <div class="sig"></div>
            <div class="page_name">活动价格列表</div>
        </div>
        <!-- 表格内容 -->
        <div class="avatar_table">
            <el-card>
                <!-- 下拉框  导出按钮部分 -->
                <div class="out_sel_btn">
                    <div class="sel_inp">
                        <el-select v-model="campus_id" placeholder="校区" clearable @change="initData" size="mini">
                            <el-option v-for="item in campus_arr" :key="item.uid" :label="item.name" :value="item.uid">
                            </el-option>
                        </el-select>
                        <el-input placeholder="请输入活动名称" v-model="search_inp" clearable @change="initData" size="mini">
                        </el-input>
                    </div>
                    <el-button type="primary" @click="addtion">添加</el-button>
                </div>
                <!-- 内容表格部分 -->
                <div class="table_init">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                        @selection-change="handleSelectionChange"
                        :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="uid" label="ID">
                        </el-table-column>
                        <el-table-column prop="name" label="活动名称">
                        </el-table-column>
                        <el-table-column prop="active_price" label="金额">
                        </el-table-column>
                        <el-table-column prop="closing_date" label="报名截止日期">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <span @click="handle_Edit(scope.row.uid)">编辑</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页部分 -->
                <Paging :count="count" :initData="initData" @changePage="handle_changePage"></Paging>
            </el-card>
            <!-- 弹框部分 -->
            <!-- 添加编辑弹框 -->
            <el-dialog :visible.sync="edit_dialog" width="828px" :before-close="handleClose" @close="handle_reset">
                <!-- 标题 -->
                <div class="tipes">
                    <div class="sd"></div>
                    <div class="ax">{{title_name}}</div>
                </div>
                <!-- 表单验证内容 -->
                <el-form label-width="120px">
                    <el-form-item label="活动名称">
                        <el-select v-model="activity_name" placeholder="请选择活动名称" clearable :disabled="dis_name"
                            size="mini">
                            <el-option v-for="item in activity_arr" :key="item.uid" :label="item.name"
                                :value="item.uid">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="缴费日期">
                        <div class="time_activ">
                            <el-date-picker style="margin-right:6px" v-model="activity_start" type="date"
                                placeholder="开始日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :disabled="dis_name"
                                size="mini">
                            </el-date-picker>~
                            <el-date-picker style="margin-left:6px" v-model="activity_end" type="date"
                                placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :disabled="dis_name"
                                size="mini">
                            </el-date-picker>
                            <div style="font-size:14px;font-weight:400;color:#d61c1c">当日23:59:59结束</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="金额">
                        <el-input v-model="money_num" placeholder="请输入金额(元)" clearable :disabled="dis_name" size="mini">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="活动内容/链接">
                        <el-input type="textarea" v-model="activity_content" placeholder="请输入活动内容/链接" clearable
                            style="width:472px">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="照片" class="img_load">
                        <div style="display:flex">
                            <el-input style="width:376px;height:30px" placeholder="请上传照片" disabled></el-input>
                            <el-upload class="upload-demo" action="https://education.ztxinde.com/active_send_upload"
                                :headers=myHeaders name="image" :on-success="handleSuccess_img" :show-file-list="false"
                                accept=".png,.gif,.jpg,.jpeg">
                                <el-button type="primary" style="width:80px;height:30px;margin-left:16px" size="small">
                                    上传照片
                                </el-button>
                            </el-upload>
                        </div>
                    </el-form-item>
                    <el-form-item class="video_img" v-if="show">
                        <img :src="img_url" alt="">
                    </el-form-item>
                    <!-- <el-form-item label="状态">
                        <el-radio v-model="radio" label="1">正常</el-radio>
                        <el-radio v-model="radio" label="2">禁用</el-radio>
                    </el-form-item> -->
                </el-form>
                <!-- 提交 重置 按钮 -->
                <div class="formBtn">
                    <div class="del_tips">以上信息一经发送即刻生效</div>
                    <el-button type="primary" class="add_confirm" @click="handle_confirm">{{btn_name}}</el-button>
                    <el-button class="add_Reset" @click="handle_reset">重置</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
let auth_token = sessionStorage.getItem("Token");
import Paging from '@/components/Paging';
import { getSetingApi, showSetingApi, addSetingApi, editSetingApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 登录用户id
            user_id: '',
            // token
            myHeaders: {
                'Token': auth_token
            },
            // 弹框显示隐藏
            edit_dialog: false,   //编辑弹框
            // 每条数据的id
            id: '',
            //  表格数据
            tableData: [],
            multipleSelection: [],
            // 分页
            page: 1,
            count: 0,
            max: 10,
            // 搜索输入框
            search_inp: '',
            // 搜索下拉框 校区
            campus_arr: [],
            campus_id: '',
            // *****弹框表单验证********
            // 弹框标题  是添加还是编辑
            title_name: '',
            btn_name: '',
            activity_name: '',   //活动名称
            activity_arr: [],
            activity_start: '',   //活动开始时间
            activity_end: '',   //活动结束时间
            money_num: '',     //金额
            activity_content: '',   //内容
            radio: '1',   //状态
            push_radio: 1,
            // 上传图片地址
            img_url: '',
            // 视频图片是否显示
            show: false,
            // 是否禁用
            dis_name: false,
        }
    },
    created () {
        this.initData();
    },
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        },
    },
    methods: {
        // 子组件传过来的当前页数
        handle_changePage (data) {
            this.page = data;
        },
        // 初始化数据
        initData () {
            this.user_id = sessionStorage.getItem('id');
            getSetingApi({
                page: this.page,   //当前页
                name: this.search_inp,  //校区名称
                campus_id: this.campus_id,     //校区id
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                    this.campus_arr = res.campus;  //校区
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 表格的方法
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange (val) {
            this.multipleSelection = val;
        },
        // 弹窗关闭
        handleClose () {
            this.edit_dialog = false;
        },
        // 点击添加按钮
        addtion () {
            this.edit_dialog = true;
            this.title_name = '添加';
            this.btn_name = '确认添加';
            showSetingApi({
                auth_userid: this.user_id,   //登录用户id
                type: 1,
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.activity_arr = res.actives;   //活动名称
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 照片上传成功
        handleSuccess_img (res, file, fileList) {
            if (res.status.code == 200) {
                this.show = true;
                this.$message.success('图片上传成功！');
                this.img_url = res.status.img;
            } else {
                this.show = false;
                this.$message.error(res.status.msg);
            }
        },
        // 点击编辑按钮
        handle_Edit (id) {
            this.edit_dialog = true;
            this.id = id;
            this.title_name = '修改';
            this.btn_name = '确认修改';
            this.dis_name = true;
            showSetingApi({
                id: id,
                auth_userid: this.user_id,   //登录用户id
                type: 2,
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.activity_arr = res.actives;   //活动名称
                    this.activity_name = res.data.active_uid;   //活动名称id
                    this.money_num = res.data.active_price;   //价格
                    this.activity_content = res.data.content;   //内容
                    this.activity_start = res.data.start_date;   //开始日期
                    this.activity_end = res.data.end_date;    //结束日期
                    this.img_url = res.data.price_img;    //图片
                    if (this.img_url != '') {
                        this.show = true;
                    };
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 弹框点击确认按钮
        handle_confirm () {
            if (this.radio == '1') {
                this.push_radio = 1
            } else {
                this.push_radio = 2
            };
            if (this.title_name == '添加') {
                addSetingApi({
                    active_uid: this.activity_name,  //活动名称
                    active_price: this.money_num, // 价格
                    start_date: this.activity_start,  //开始日期
                    end_date: this.activity_end,  //关闭日期
                    content: this.activity_content,  //内容
                    // status: this.push_radio,  // 状态
                    price_img: this.img_url,   //图片地址
                    status: this.push_radio,   //状态
                    auth_userid: this.user_id, //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.initData();
                        this.handle_reset();
                        this.edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.title_name == '修改') {
                editSetingApi({
                    id: this.id,
                    active_uid: this.activity_name,  //活动名称
                    active_price: this.money_num, // 价格
                    start_date: this.activity_start,  //开始日期
                    end_date: this.activity_end,  //关闭日期
                    content: this.activity_content,  //内容
                    // status: this.push_radio,  // 状态
                    price_img: this.img_url,   //图片地址
                    status: this.push_radio,   //状态
                    auth_userid: this.user_id, //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.initData();
                        this.handle_reset();
                        this.edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 弹框点击重置按钮
        handle_reset () {
            this.activity_name = '';   //活动名称
            this.activity_start = '';   //活动开始时间
            this.activity_end = '';   //活动结束时间
            this.money_num = '';   //金额
            this.activity_content = '';    //内容
            this.radio = '1';   //状态
            this.show = false;   //图片
            this.img_url = '';
            this.dis_name = false
        },
    }
}
</script>

<style lang="scss" scoped>
.role_main {
    width: 100%;
    height: 100%;
    // 页面标题部分
    .title_page {
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .sig {
            width: 18px;
            height: 4px;
            margin-right: 8px;
            background: #2479dd;
        }
        .page_name {
            font-size: 18px;
            font-weight: 600;
        }
    }
    // 表格显示内容
    .avatar_table {
        margin-bottom: 20px;
        // 下拉框  导出按钮部分
        .out_sel_btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // 下拉框部分
            .sel_inp {
                margin-top: 15px;
                display: flex;
                .el-select {
                    width: 140px;
                    height: 30px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    ::v-deep.el-input__inner {
                        width: 140px;
                        height: 30px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        ::v-deep.el-select__caret {
                            line-height: 30px;
                        }
                        ::v-deep.el-input__icon {
                            line-height: 30px;
                        }
                    }
                }
                .el-input {
                    width: 155px;
                    height: 30px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-left: 0px;
                    ::v-deep.el-input__inner {
                        width: 155px;
                        height: 30px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        border-left: 0px;
                    }
                }
            }
            .el-button {
                width: 80px;
                height: 30px;
                line-height: 4px;
                margin-right: 8px;
            }
        }
        // 表格内容
        .table_init {
            margin-top: 20px;
            text-align: center;
            // 操作按钮
            span {
                border: none;
                width: 24px;
                height: 18px;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }
    // 弹框部分
    .el-dialog {
        text-align: center;
        // border-radius: 12px;
        // 标题部分
        .tipes {
            display: flex;
            align-items: center;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
            .sd {
                width: 18px;
                height: 4px;
                margin-right: 8px;
                background: #2479dd;
            }
            .ax {
                color: #000000;
                font-size: 16px;
                font-weight: 600;
            }
        }
        //  添加  编辑 表单部分
        .el-form {
            text-align: left;
            margin: 30px 0 50px 100px;
            .el-form-item {
                .el-input {
                    width: 472px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 472px;
                    height: 30px;
                }
            }
            //照片上传
            .img_load {
                ::v-deep.el-input__inner {
                    width: 376px;
                    height: 30px;
                }
            }
            .video_img {
                img {
                    width: 100px;
                    height: 100px;
                }
            }
            .time_activ {
                .el-input {
                    width: 224px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 224px;
                    height: 30px;
                }
            }
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 68px 0 80px;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .del_tips {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 12px;
                color: #d61c1c;
            }
            .el-button {
                width: 98px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
            }
            // 确认按钮  添加  编辑
            .add_confirm {
                margin-right: 16px;
            }
            // .add_Reset {
            //     position: relative;
            //     left: 0;
            //     top: 5px;
            // }
        }
    }
}
</style>